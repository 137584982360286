import React, { Component } from 'react'
import SupportLayoutPage from './layout'

export default class SupportPage extends Component {
    render() {
        return (
            <SupportLayoutPage />
        )
    }
}
